import React, { useState, useEffect } from "react";
import axios from "axios";

import phone from "../../assets/img/header/menu/ic-phone.png";
import email from "../../assets/img/header/menu/ic-mail.png";
import fb from "../../assets/img/header/menu/ic-fb.png";
import lin from "../../assets/img/header/menu/ic-in.png";
import wp from "../../assets/img/header/menu/ic-wp.png";

const TopHeader = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState({});

    useEffect(() => {
        const handleGetAllData = () => {
            axios
                .get("content-group/9")
                .then(res => {
                    let data = res.data || [];
                    setData(data);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        handleGetAllData();
    }, [])

    useEffect(() => {
        if(data.Contents) {
            let phoneList = data.Contents.filter(item => item.Key.includes("geral-contato-telefone")).map(item => item.Value);
            let emailList = data.Contents.filter(item => item.Key.includes("geral-contato-email")).map(item => item.Value);
            let facebook = data.Contents.find(item => item.Key === "geral-contato-facebook").Value;
            let linkedin = data.Contents.find(item => item.Key === "geral-contato-linkedin").Value;
            let whatsapp = data.Contents.find(item => item.Key === "geral-contato-whatsapp").Value;

            setAllData(allData => ({
                ...allData,
                phoneList,
                emailList,                
                facebook,
                linkedin,
                whatsapp,
            }))
        }
    }, [data])

    return (
        <div className="top__header">
            <div className="content">
                <span>Trabalhe Conosco:</span>
                <div className="d-f-a-c">
                    <a href={`tel:+55${allData.phoneList ? allData.phoneList[0].replace(/[^a-zA-Z0-9]/g, "") : "5433156990"}`} style={{display: "flex", alignItems: "center"}}>
                        <img src={phone}  alt="Telefone" className="icon__social"></img>
                        <span>
                            {allData.phoneList ? allData.phoneList[0] : "(54) 3315-6990"}
                        </span>
                    </a>                        
                </div>
                <div className="d-f-a-c">
                    <a href={`mailto:${allData.emailList ? allData.emailList[0] : "ezata@ezata.com.br"}`} style={{display: "flex", alignItems: "center"}}>
                        <img src={email}  alt="Email" className="icon__social"></img>
                        <span>
                            {allData.emailList ? allData.emailList[0] : "ezata@ezata.com.br"}       
                        </span>
                    </a>                         
                </div>
                <div>
                    <a target="_blank" rel="noreferrer" href={allData.facebook}>
                        <img src={fb}  alt="FB" className="icon__social"></img>
                    </a>
                    <a target="_blank" rel="noreferrer" href={allData.linkedin}>
                        <img src={lin} alt="IN" className="icon__social"></img>
                    </a>
                    <a target="_blank" rel="noreferrer" href={allData.whatsapp}>
                        <img src={wp}  alt="WP" className="icon__social"></img>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default TopHeader
