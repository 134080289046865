import React from "react"
import Intent from "../../link"
import { Helmet } from "react-helmet"

import logo from "../../../assets/img/header/menu/ic-logo.png";
import data from './data';
import SearchBox from "./SearchBox";

const MenuItems = (props) => {
    const infoPage = props.infoPage;
    const page = infoPage.page;
    const subPage = infoPage.sub ? infoPage.sub : false;
    
    const handlePageName = () => {
        let allName = page.charAt(0).toUpperCase() + page.slice(1);
        if(subPage) {
            allName = allName + ' - ' + subPage.charAt(0).toUpperCase() + subPage.slice(1);
        }

        allName = allName + ` | Ezata`;
        return allName;
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{handlePageName()}</title>
                <link rel="canonical" href="https://ezata.com.br" />
            </Helmet>
            <header className={!props.isMobile ? 'menu' : 'menu mobile'}>
                <div className={props.isMobile ? 'display-none' : null}>
                    <Intent to="/">
                        <img src={logo} alt="Crafty Logo" className="logo" />
                    </Intent>
                </div>
                <div className="d-f-a-c">
                    {
                        props.isMobile ? 
                        <div className="menu__box">
                            <Intent to="/">
                                <span className={page === "/" ? "menu--active" : "button"}>
                                    Home
                                </span>
                            </Intent>
                        </div>
                        : null
                    }
                    <div className="menu__box">
                        
                        <Intent to="/sobre">
                            <span className={page === "sobre" ? "menu--active" : "button"}>
                                {data[0].Title}
                            </span>
                        </Intent>
                        <div className="sub__menu">
                            {data[0].Items.map((item, i) => (
                                <Intent to={`/sobre${item.URL}`} key={i}>
                                    <span className={subPage === item.URL ? "menu--active" : "button"}>
                                        {item.Name}
                                    </span>
                                </Intent>    
                            ))}
                        </div>                    
                    </div>
                    <div className="menu__box arrow" >
                        <Intent to="/tecnologia">
                            <span className={page === "tecnologia" ? "menu--active" : "button"}>
                                {data[1].Title}
                            </span>
                        </Intent>
                        <div className="sub__menu">
                            {data[1].Items.map((item, i) => (
                                <Intent to={`/tecnologia${item.URL}`} key={i}>
                                    <span className={subPage === item.URL ? "menu--active" : "button"}>
                                        {item.Name}
                                    </span>
                                </Intent>    
                            ))}
                        </div>
                    </div>
                    <div className="menu__box">
                        <Intent to="/produtos">
                            <span className={page === "produtos" ? "menu--active" : "button"}>
                                Produtos
                            </span>
                        </Intent>
                    </div>
                    <div className="menu__box">
                        <Intent to="/contato">
                            <span className={page === "contato" ? "menu--active" : "button"}>
                                Contato
                            </span>
                        </Intent>
                    </div>
                    <SearchBox />
                </div>
            </header>
        </>   
    )
};

export default MenuItems;