import React from "react"
import menuIcon from "../../../assets/img/menu.svg"
import MenuItems from './MenuItems'



const Menu = ({props}) => {
    const openSidebar = () => {
        document.getElementById("sidebar").style.display = "block"
    }

    const closeSidebar = () => {
        document.getElementById("sidebar").style.display = "none"
    }
    
        let infoPage = props.infoPage
        const menuToShow = <MenuItems infoPage={infoPage} isMobile={props.smallScreen} />;
        return props.smallScreen ? (
            <div className="sidebar">
                <button onClick={openSidebar} className="sidebar-button-off">
                    <img src={menuIcon} alt="Menu" className="menu-icon"></img>
                </button>
                <div
                    className="sidebar-inner"
                    style={{ display: "none" }}
                    id="sidebar"
                    onClick={closeSidebar}
                    onKeyDown={closeSidebar}
                    role="button"
                    tabIndex={0}
                >
                    <button className="sidebar-button-on">
                        <img src={menuIcon} alt="Menu" className="menu-icon"></img>
                    </button>
                    { menuToShow }
                </div>
            </div>
        ) : (
            <>
            { menuToShow }
            </>
        )
}
export default Menu
