import React, {createRef } from "react"
// import Intent from "./link"
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { navigate } from "gatsby"
// import PaintDrip from "gatsby-plugin-transition-link/AniLink"

import magnify from "../../../assets/img/header/menu/ic-magnify.png";
import magnifyWhite from "../../../assets/img/header/menu/ic-magnify-white.png";

const SearchBox = (props) => {
    const searchBoxRef = createRef();
    const inputSearchBoxRef = createRef();
    const searchToggle = () => {
        let el = searchBoxRef.current;
        if(!el.classList.contains('display-flex-imp')){
            el.classList.add('display-flex-imp');
        }
        else if(el.classList.contains('display-flex-imp')){
        // else if(container.classList.contains('active')){
            el.classList.remove('display-flex-imp');
            // clear input
            setTimeout(() => {
                inputSearchBoxRef.current.value = '';
            }, 400);
        }
    }
    const handleSearchMenu = () => {
        if(inputSearchBoxRef.current.children[0].children[0].value !== '') {
            allmenus.forEach(el => {
                if(el.title === inputSearchBoxRef.current.children[0].children[0].value) navigate(el.page);
            })
        }
    }
    const allmenus = [
        { title: 'Home', page: "/" },
        { title: 'Sobre', page: "/sobre" },
        { title: 'Engenharia', page: "/tecnologia/engenharia" },
        { title: 'Corte', page: "/tecnologia/corte" },
        { title: 'Dobra', page: "/tecnologia/dobra" },
        { title: 'Solda', page: "/tecnologia/solda" },
        { title: 'Estamparia', page: "/tecnologia/estamparia" },
        { title: 'Pintura', page: "/tecnologia/pintura" },
        { title: 'Usinagem', page: "/tecnologia/usinagem" },
        { title: 'Tecnologia', page: "/tecnologia" },
        { title: 'Contato', page: "/contato" },
      ];
    return (
        <span className={!props.isMobile ? 'search' : 'display-none'}>
            <div role="button" tabIndex={-1} onClick={() => searchToggle()} onKeyDown={() => searchToggle()}><img src={magnify} alt="Buscar" className="search__img" /></div>
            <div className="box__search" ref={searchBoxRef}>
                <Autocomplete
                    options={allmenus}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField ref={inputSearchBoxRef} {...params} />}
                />
                <button  onClick={() => handleSearchMenu()}>
                    <img src={magnifyWhite} alt="Buscar" className="search__img"></img>
                </button>
            </div>
        </span>
    )
};

export default SearchBox;