
const data = [
	{
		Title: "Sobre",
		Items: [
			// {
			// 	Name: 'História',
			// 	URL: '/historia'
			// },
			// {
			// 	Name: 'Equipe',
			// 	URL: '/equipe'
			// },
			// {
			// 	Name: 'Missão, Valores e Visão',
			// 	URL: '/missao'
			// },
			// {
			// 	Name: 'Vídeo Institucional',
			// 	URL: '/video'
			// },
			// {
			// 	Name: 'Somos Mais',
			// 	URL: '/somos-mais'
			// },
			// {
			// 	Name: 'Sustentabilidade',
			// 	URL: '/sustentabilidade'
			// },
			// {
			// 	Name: 'Transporte',
			// 	URL: '/transporte'
			// },
		]
	},
	{
		Title: "Tecnologia",
		Items: [
			{
				Name: 'Engenharia',
				URL: '/engenharia'
			},
			{
				Name: 'Corte',
				URL: '/corte'
			},
			{
				Name: 'Dobra',
				URL: '/dobra'
			},
			{
				Name: 'Solda',
				URL: '/solda'
			},
			{
				Name: 'Estamparia',
				URL: '/estamparia'
			},
			{
				Name: 'Pintura',
				URL: '/pintura'
			},
			{
				Name: 'Usinagem',
				URL: '/usinagem'
			},
		]
	},
]

export default data;