import React, { useState, useEffect } from "react"
import axios from "axios";

import logo from "../../assets/img/header/menu/ic-logo-white.png";
import fbIconWhite from "../../assets/img/header/menu/ic-fb-white.png";
import inIconWhite from "../../assets/img/header/menu/ic-in-white.png";
import localizacaoIcon from "../../assets/img/header/menu/ic-localizacao.png";
import phoneIcon from "../../assets/img/header/menu/ic-phone.png";
import wpIcon from "../../assets/img/header/menu/ic-wp.png";
import mailIcon from "../../assets/img/header/menu/ic-mail.png";
import Intent from "../link";

const Footer = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState({});

    useEffect(() => {
        const handleGetAllData = () => {
            axios
                .get("content-group/9")
                .then(res => {
                    let data = res.data || [];
                    setData(data);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        handleGetAllData();
    }, [])

    useEffect(() => {
        if(data.Contents) {
            let phoneList = data.Contents.filter(item => item.Key.includes("geral-contato-telefone")).map(item => item.Value);
            let emailList = data.Contents.filter(item => item.Key.includes("geral-contato-email")).map(item => item.Value);
            let endereco = data.Contents.find(item => item.Key === "geral-contato-endereco").Value;
            let facebook = data.Contents.find(item => item.Key === "geral-contato-facebook").Value;
            let linkedin = data.Contents.find(item => item.Key === "geral-contato-linkedin").Value;
            let whatsapp = data.Contents.find(item => item.Key === "geral-contato-whatsapp").Value;

            setAllData(allData => ({
                ...allData,
                phoneList,
                emailList,
                endereco,
                facebook,
                linkedin,
                whatsapp,
            }))
        }
    }, [data])
    return (
        <footer className="footer upper">
            <div className="wrapper">
                <div className="footer__main__info">
                    <img className="footer__img" src={logo} alt="logo" />
                    <p className="footer__paragraph">A Ezata Industrial busca sempre oferecer o melhor trabalho final para seu cliente, com equipamentos de alta qualidade e procedimentos sustentáveis.</p>
                    <span>
                        <a href={allData.facebook} target="_blank" rel="noreferrer"><img className="footer__social__icon" src={fbIconWhite} alt="facebook" /></a>
                        <a href={allData.linkedin} target="_blank" rel="noreferrer"><img className="footer__social__icon" src={inIconWhite} alt="linkedin" /></a>
                    </span>
                </div>
                <section className="links">
                    <ul className="footer__list__links">
                        <li><Intent to="/sustentabilidade">Sustentabilidade</Intent></li>
                        <li><Intent to="/transporte">Transporte</Intent></li>
                        <li><Intent to="/materiais">Materiais</Intent></li>
                        <li><Intent to="/somos-mais">Somos +</Intent></li>
                        <li><Intent to="/trabalhe-conosco">Trabalhe conosco</Intent></li>
                    </ul>
                    <ul className="footer__list__info">
                        <span>
                            <img src={localizacaoIcon} alt="localização" />
                            <li>{allData.endereco ? allData.endereco : "Av. Brasil Oeste, 4680 Estrada de Santo Antão, s/n, Passo Fundo - RS, 99025-000"}</li>
                        </span>
                        <span>
                            <a href={`tel:+55${allData.phoneList ? allData.phoneList[0].replace(/[^a-zA-Z0-9]/g, "") : "5433156990"}`} style={{ display: "flex", alignItems: "flex-start" }}>
                                <img src={phoneIcon} alt="telefone" />
                                {allData.phoneList ? allData.phoneList[0] : "(54) 3315-6990"}
                            </a>
                        </span>
                        <span>
                            <a target="_blank" rel="noreferrer" href={allData.whatsapp ? allData.whatsapp : "https://api.whatsapp.com/send?phone=5554991950844&text=Ol%C3%A1%2C%20tudo%20bem%3F"} style={{ display: "flex", alignItems: "flex-start" }}>
                                <img src={wpIcon} alt="whatsapp" />
                                <li>{allData.phoneList ? allData.phoneList[1] : "(54) 3315-6990"}</li>
                            </a>
                        </span>
                        <span>
                            <a href={`mailto:${allData.emailList ? allData.emailList[0] : "ezata@ezata.com.br"}`} style={{ display: "flex", alignItems: "flex-start" }}>
                                <img src={mailIcon} alt="email" />
                                <li>{allData.emailList ? allData.emailList[0] : "ezata@ezata.com.br"}</li>
                            </a>
                        </span>
                    </ul>
                </section>
            </div>
        </footer>

    )
}

export default Footer;
