import React from "react"
import { Menu } from "./menu"
import TopHeader from "./TopHeader"

const Header = (props) => {
        return (
            <>
                <TopHeader />
                <Menu props={props} />
            </>
        )
}
export default Header
